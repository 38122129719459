import React from "react"
import { truncate } from "../../utils/truncate"
import { Link } from "gatsby"
import styled from "styled-components"

const StyledArticleSnippetHome = styled.article`
  margin-bottom: 3rem;

  svg.triangle {
    display: flex;
    width: 15px;
    margin: auto;

    path {
      fill: ${({ theme }) => theme.secondary};
    }
  }
`

const ArticleHeading = styled.h5`
  font-family: "Radikal-MediumItalic";
  font-size: 1.375rem;
`

export const ArticleSnippetHome = ({
  article,
  variant = "primary",
  triangle,
}) => {
  // console.log(article)
  return (
    <StyledArticleSnippetHome>
      <ArticleHeading>
        <Link className={`text-${variant}`} to={article.permalink}>
          {article.title}
        </Link>
      </ArticleHeading>
      <p>{article.snippet && truncate(article.snippet.snippet, 200, true)}</p>
      <Link to={article.permalink} aria-label={article.title}>
        {triangle ? (
          <svg className="triangle" viewBox="0 0 31 29">
            <path d="M15.5,29L0,0h31L15.5,29z" />
          </svg>
        ) : (
          "Read More..."
        )}
      </Link>
    </StyledArticleSnippetHome>
  )
}
